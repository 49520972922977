import React from "react";
import Modal from "../../components/Modal/Modal";
import { SanityEventOccurrence } from "../../model/events";
import EventDetails from "../EventDetails/EventDetails";
import styles from "./EventModal.module.scss";

interface EventModalProps {
  value: SanityEventOccurrence;
  isOpen: boolean;
  onClose: () => void;
}

const EventModal = ({ value, isOpen, onClose }: EventModalProps) => {
  const { event } = value;
  return (
    <Modal
      id="event"
      isOpen={isOpen}
      title={event.title}
      onClose={onClose}
      className={styles.root}
    >
      <EventDetails event={event} date={value.date} showViewDetails />
    </Modal>
  );
};

export default EventModal;
