import useLocation from "./useLocation";
import { useState, useEffect } from "react";

export const useSearchParams = (): URLSearchParams | null => {
  const location = useLocation();
  // We need to ensure the first render matches the SSR, which
  // will not have had these params available. Otherwise madness
  // ensues. If this isn't what you want, consider making them
  // match by using a URL path parameter and doing SSR.
  const [ready, setReady] = useState(false);
  useEffect(() => {
    setReady(true);
  }, []);
  return ready ? new URLSearchParams(location.search) : null;
};

export const useSearchParam = (param: string): string | null => {
  const params = useSearchParams();
  return params === null ? null : params.get(param);
};
