import React, { useEffect, useState } from "react";

export const dayMonthFormat: Intl.DateTimeFormatOptions = {
  month: "short",
  day: "2-digit"
};

export const defaultDateTimeFormat: Intl.DateTimeFormatOptions = {
  dateStyle: "medium",
  timeStyle: "short"
};

const LocalDateTimeFormat = ({
  value,
  options = defaultDateTimeFormat
}: {
  value: string;
  options?: Intl.DateTimeFormatOptions;
}) => {
  const [ready, setReady] = useState(false);
  // Don't use Intl in SSR
  useEffect(() => {
    setReady(true);
  }, []);
  if (!ready) {
    return null;
  }
  return (
    <span>
      {Intl.DateTimeFormat(undefined, options)
        .format(new Date(value))
        .replace(",", "")}
    </span>
  );
};

export default LocalDateTimeFormat;
