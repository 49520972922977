import React, { useState } from "react";
import { internalLinkUrl } from "../../components/InternalLink/InternalLink";
import {
  FilterValue,
  SanityTeachFeaturedRow,
  TeachFeaturedRowConfig
} from "../../model/teach";
import TeachFilters from "../TeachFilters/TeachFilters";
import TeachRow from "../TeachRow/TeachRow";

interface TeachFeaturedRowProps<V> {
  row: SanityTeachFeaturedRow;
  config: TeachFeaturedRowConfig<V>;
}

const TeachFeaturedRow = <V,>({ row, config }: TeachFeaturedRowProps<V>) => {
  const { title, subtitle, _rawContent: content, viewMoreText } = row;
  const viewAllURL = row.internalLink
    ? internalLinkUrl(
        row.internalLink.reference,
        row.internalLink.query,
        row.internalLink.fragment,
        undefined
      )
    : row.externalLink!;
  const filterValues: FilterValue[] = config.filterValues
    ? config.filterValues()
    : [];
  const defaultFilterValue = filterValues.length ? filterValues[0] : undefined;
  const [filter, setFilter] = useState<FilterValue | undefined>(
    defaultFilterValue
  );
  const data = config.data(row, filter);
  const cards = data.map(d => {
    const card = config.card(d);
    if (!card.key) {
      throw new Error("Missing keys for " + row.title);
    }
    return card;
  });
  const filters = (
    <TeachFilters
      selected={filter}
      onSelected={setFilter}
      filterValues={filterValues}
    />
  );
  return (
    <TeachRow
      viewMoreText={viewMoreText}
      viewMoreURL={viewAllURL}
      cards={cards}
      title={title}
      subtitle={subtitle}
      filters={filters}
      showFilters={filterValues.length > 0}
      content={content}
    />
  );
};

export default TeachFeaturedRow;
