import React from "react";
import CardContent from "../../components/CardContent/CardContent";
import { SanityPdCourse } from "../../model/teach";
import { urlForDocument } from "../../urls";
import { ImageRatios } from "../Image/Image";
import ImageCard from "../ImageCard/ImageCard";

interface CourseCardProps {
  course: SanityPdCourse;
  textMaxLines?: number;
}

const CourseCard = ({ course, textMaxLines = 2 }: CourseCardProps) => {
  const { image, title } = course;
  const href = urlForDocument(course);
  return (
    <ImageCard
      widths={400}
      image={image}
      aspectRatio={ImageRatios.Landscape_16_9}
      href={href}
    >
      <CardContent
        title={title}
        titleVariant="defaultBold"
        href={href}
        textMaxLines={textMaxLines}
        text={course.description}
      />
    </ImageCard>
  );
};

export default CourseCard;
