import React from "react";
import CardContent from "../../components/CardContent/CardContent";
import { ImageRatios } from "../../components/Image/Image";
import ImageCard from "../../components/ImageCard/ImageCard";
import { SanityResourcePage } from "../../model/teach";
import { urlForDocument } from "../../urls";

interface ResourceCardProps {
  className?: string;
  resource: SanityResourcePage;
}

const ResourceCard = ({ resource, className }: ResourceCardProps) => {
  const url = urlForDocument(resource);
  return (
    <ImageCard
      className={className}
      image={resource.image}
      aspectRatio={ImageRatios.Landscape_4_3}
      widths={400}
      href={url}
    >
      <CardContent
        title={resource.title}
        titleVariant="h4"
        text={resource.subtitle}
        href={url}
        textMaxLines={3}
      />
    </ImageCard>
  );
};

export default ResourceCard;
