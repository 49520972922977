import React from "react";
import Radio from "../../components/Inputs/Radio/Radio";
import { FilterValue } from "../../model/teach";

const TeachFilters = ({
  filterValues,
  selected,
  onSelected
}: {
  selected: FilterValue | undefined;
  filterValues: FilterValue[];
  onSelected: (value: FilterValue) => void;
}) => {
  return filterValues.length > 0 ? (
    <>
      {filterValues.map(filterValue => (
        <Radio
          key={filterValue._id}
          label={filterValue.name}
          name={filterValue._id.toString()}
          selected={selected?._id === filterValue._id}
          onChange={() => {
            onSelected(filterValue);
          }}
          value={filterValue._id}
        />
      ))}
    </>
  ) : null;
};

export default TeachFilters;
