import { graphql, useStaticQuery } from "gatsby";
import sortBy from "lodash.sortby";
import { useMemo } from "react";
import { SanityDocument } from "../model/common";
import { SanityEvent, SanityEventOccurrence } from "../model/events";
import { urlForDocument } from "../urls";
import { useSearchParams } from "../utils/useSearchParam";

export const useEventOccurrences = (events: SanityEvent[]) => {
  const {
    siteBuildMetadata: { buildTime }
  } = useStaticQuery(graphql`
    query BuildTime {
      siteBuildMetadata {
        buildTime
      }
    }
  `);
  // We use site build time to avoid hydration mismatches. Even though teach does this
  // client side we also have the event listing page.
  return useMemo(() => eventsToEventOccurrences(events, new Date(buildTime)), [
    events,
    buildTime
  ]);
};

const eventsToEventOccurrences = (events: SanityEvent[], now: Date) => {
  const flattened = events.flatMap(event =>
    event.dates.map(date => {
      return { event, date, _key: event._id + date.date };
    })
  );
  return sortBy(flattened, s => s!.date.date).filter(
    d => new Date(d.date.date) >= now
  );
};

export const useFocussedEventOccurrence = (
  occurrences: SanityEventOccurrence[]
): SanityEventOccurrence | undefined => {
  const params = useSearchParams();
  if (params) {
    const event = params.get("event");
    const date = params.get("date");
    if (event && date) {
      return occurrences.find(
        o => o.date.date === date && o.event.slug.current === event
      );
    }
  }
  return undefined;
};

export const eventModalUrl = (
  page: SanityDocument,
  event: SanityEventOccurrence
) => {
  return (
    urlForDocument(page) +
    `?event=${encodeURIComponent(
      event.event.slug.current
    )}&date=${encodeURIComponent(event.date.date)}`
  );
};
