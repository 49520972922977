import React, { useEffect, useState } from "react";
import { useConsent } from "../../utils/compliance";
import { Wrapper } from "../../components/Grid";
import Spinner from "../../components/Spinner/Spinner";
import VisuallyHidden from "../../components/VisuallyHidden/VisuallyHidden";
import { fetchCachedBrowserInfo } from "../../utils/browser-info";
import TeachFeaturedRow from "../../components/TeachFeaturedRow/TeachFeaturedRow";
import {
  RowTypes,
  SanityTeachCampaign,
  SanityTeachFeaturedRow
} from "../../model/teach";
import TeachCampaignCarousel from "../TeachCampaignCarousel/TeachCampaignCarousel";
import TeachCampaignItem from "../TeachCampaignItem/TeachCampaignItem";
import styles from "./TeachFeaturedContent.module.scss";

interface TeachFeaturedContentProps {
  rows: SanityTeachFeaturedRow[];
  rowTypes: RowTypes;
  campaigns: SanityTeachCampaign[];
}

const TeachFeaturedContent = ({
  rows,
  rowTypes,
  campaigns
}: TeachFeaturedContentProps) => {
  const consent = useConsent();
  // Rows filtered by country.
  const [filteredRows, setFilteredRows] = useState<SanityTeachFeaturedRow[]>();
  // Campaigns filtered by country, initially filtering out campaigns restricted by country.
  const [filteredCampaigns, setFilteredCampaigns] = useState<
    SanityTeachCampaign[]
  >(campaigns.filter(campaign => !campaign.countries.length));
  useEffect(() => {
    const getFilteredRowsCampaigns = async () => {
      if (
        rows.some(row => !!row.countries.length) ||
        campaigns.some(campaign => !!campaign.countries.length)
      ) {
        const { country } = await fetchCachedBrowserInfo(consent);
        setFilteredRows(
          rows.filter(row =>
            row.countries.length
              ? row.countries.some(c => c.code === country)
              : row
          )
        );
        setFilteredCampaigns(
          campaigns.filter(campaign =>
            campaign.countries.length
              ? campaign.countries.some(c => c.code === country)
              : campaign
          )
        );
      } else {
        setFilteredRows(rows);
      }
    };
    if (consent) {
      getFilteredRowsCampaigns();
    }
  }, [campaigns, consent, rows]);

  return (
    <>
      <VisuallyHidden>
        <h1>Teach featured</h1>
      </VisuallyHidden>
      <Wrapper className={styles.hero}>
        <TeachCampaignCarousel
          slides={filteredCampaigns.map(campaign => (
            <TeachCampaignItem key={campaign._id} {...campaign} />
          ))}
        />
      </Wrapper>
      <Wrapper className={styles.content}>
        {filteredRows ? (
          filteredRows.map((row, index) => {
            const config = rowTypes[row.type];
            if (!config) {
              // Make it easy to add new row types.
              return null;
            }
            return <TeachFeaturedRow key={index} row={row} config={config} />;
          })
        ) : (
          <Spinner />
        )}
      </Wrapper>
    </>
  );
};

export default TeachFeaturedContent;
