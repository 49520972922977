import classNames from "classnames";
import React, { useState } from "react";
import BlockContent from "../../components/BlockContent/BlockContent";
import Button from "../../components/Button/StandardButton/Button";
import Image, { ImageRatios } from "../../components/Image/Image";
import Link from "../../components/Link/Link";
import LocalDateTimeFormat from "../../components/LocalDateTimeFormat/LocalDateTimeFormat";
import Text from "../Text/Text";
import GetText from "../../i18n/GetText";
import { SanityEvent, SanityEventDate } from "../../model/events";
import { urlForDocument } from "../../urls";
import styles from "./EventDetails.module.scss";

interface EventDetailsProps {
  event: SanityEvent;
  date?: SanityEventDate;
  showViewDetails?: boolean;
}

const EventDetails = ({ event, date, showViewDetails }: EventDetailsProps) => {
  const [chosenDate, setChosenDate] = useState<SanityEventDate>(
    date ?? event.dates[0]
  );
  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.image}>
          <Image
            className={styles.image}
            image={event.image}
            widths={700}
            aspectRatio={ImageRatios.Landscape_4_3}
            corners="rounded"
          />
        </div>
        <div className={styles.content}>
          <div className={styles.metadata}>
            {event.audience && (
              <Text variant="defaultLight">
                <Text variant="defaultBold" as="span">
                  Audience:{" "}
                </Text>
                {event.audience}
              </Text>
            )}
            <Text variant="defaultLight">
              <Text variant="defaultBold" as="span">
                Duration:{" "}
              </Text>
              {event.duration} mins
            </Text>
          </div>
          <BlockContent
            content={event._rawContent}
            after={
              showViewDetails && (
                <Text variant="defaultLight" className={styles.viewDetails}>
                  <Link to={urlForDocument(event)}>View event page</Link>
                </Text>
              )
            }
          />
        </div>
      </div>
      <Text variant="h3">Available dates</Text>
      <div className={styles.dates}>
        {event.dates.map(date => (
          <label
            key={date.date}
            className={classNames(
              styles.date,
              date.date === chosenDate.date && styles.selected
            )}
          >
            <div>
              <input
                type="radio"
                name="date"
                value={date.date}
                checked={date.date === chosenDate.date}
                onChange={() => setChosenDate(date)}
              />
              <Text variant="defaultBold" as="span">
                <LocalDateTimeFormat value={date.date} />
              </Text>
            </div>
            <div>
              <Text variant="default" as="span">
                Platform:
              </Text>
              <Text variant="defaultBold" as="span">
                <GetText id={`event-format-${date.format}`} />
              </Text>
            </div>
          </label>
        ))}
      </div>
      <div className={styles.buttons}>
        <Button
          primary
          onClick={() => window.location.assign(chosenDate.registrationLink)}
        >
          Register now
        </Button>
      </div>
    </div>
  );
};

export default EventDetails;
