import React, { ReactNode, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import styles from "./Radio.module.scss";
import { useWindowSize } from "react-use";
import Text from "../../Text/Text";

const Radio = ({
  id,
  label,
  name = "",
  onChange,
  selected = false,
  value = ""
}: RadioProps) => {
  // We commonly hide the input so provide other means of interactivity if required.
  const radioRef = useRef<HTMLInputElement | null>(null);
  const { width } = useWindowSize();
  const [radioHidden, setRadioHidden] = useState(false);
  useEffect(() => {
    if (radioRef.current) {
      setRadioHidden(
        window.getComputedStyle(radioRef.current).display === "none"
      );
    }
  }, [width]);
  return (
    <div className={styles.radioContainer}>
      <label
        onClick={e => {
          e.preventDefault();
          if (radioRef.current) {
            radioRef.current.click();
          }
        }}
        onKeyDown={e => {
          if (e.keyCode === 13 || e.keyCode === 32) {
            e.preventDefault();
            if (radioRef.current) {
              radioRef.current.click();
            }
          }
        }}
        role={radioHidden ? "button" : undefined}
        tabIndex={radioHidden ? 0 : undefined}
        className={classnames(`${styles.radioLabel}`, {
          [`${styles.radioSelectedFilter}`]: selected
        })}
      >
        <input
          id={id}
          type="radio"
          ref={radioRef}
          name={name}
          className={styles.radio}
          onChange={onChange}
          value={value}
          checked={selected}
        />
        <Text variant="default" as="span">
          {label}
        </Text>
      </label>
    </div>
  );
};

export default Radio;

interface RadioProps {
  id?: string;
  label: string | ReactNode;
  name: string;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  selected?: boolean;
  value: any;
}
