import React from "react";
import CardContent from "../../components/CardContent/CardContent";
import { ImageRatios } from "../../components/Image/Image";
import ImageCard from "../../components/ImageCard/ImageCard";
import LocalDateTimeFormat, {
  dayMonthFormat,
  defaultDateTimeFormat
} from "../../components/LocalDateTimeFormat/LocalDateTimeFormat";
import TagInfo from "../../components/TagInfo/TagInfo";
import Text from "../Text/Text";
import { SanityEventOccurrence } from "../../model/events";
import styles from "./EventCard.module.scss";

interface EventCardProps {
  // Passed in because we use a URL to show a quick-view modal.
  url: string;
  value: SanityEventOccurrence;
}

const EventCard = ({ url, value }: EventCardProps) => {
  const { date, event } = value;
  const { duration, audience } = event;
  const tagInfo = (
    <TagInfo
      value={[
        [<span key="duration">{duration} mins</span>],
        audience ? [<span key="audience">{audience}</span>] : []
      ]}
    />
  );
  return (
    <ImageCard
      className={styles.root}
      image={event.image}
      aspectRatio={ImageRatios.Landscape_4_3}
      widths={400}
      overlay={<EventImageOverlay value={value} />}
      href={url}
    >
      <CardContent
        title={event.title}
        titleVariant="defaultBold"
        text={
          <LocalDateTimeFormat
            value={date.date}
            options={defaultDateTimeFormat}
          />
        }
        href={url}
        textMaxLines={1}
        tags={tagInfo}
      ></CardContent>
    </ImageCard>
  );
};

interface EventImageOverlapProps {
  value: SanityEventOccurrence;
}

const EventImageOverlay = ({ value }: EventImageOverlapProps) => {
  const { date } = value;
  return (
    <Text variant="defaultBold" as="span" className={styles.overlay}>
      <LocalDateTimeFormat value={date.date} options={dayMonthFormat} />
    </Text>
  );
};

export default EventCard;
