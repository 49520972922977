import React, { useCallback, useEffect, useState } from "react";
import Swiper from "swiper";
import CarouselButton from "../../components/CarouselButton/CarouselButton";
import { Col, Row, Wrapper } from "../../components/Grid";
import SwiperCarousel from "../../components/SwiperCarousel/SwiperCarousel";
import { useIsRtl } from "../../utils/LayoutContext";
import { prefersReducedMotion } from "../../utils/user-preferences";
import styles from "./TeachCampaignCarousel.module.scss";

interface TeachCampaignCarouselProps {
  slides: JSX.Element[];
}

const TeachCampaignCarousel = ({ slides }: TeachCampaignCarouselProps) => {
  const isRtl = useIsRtl();
  const [autoplay, setAutoPlay] = useState<boolean>(false);
  const [swiper, setSwiper] = useState<Swiper>();
  const handleClick = useCallback(
    (direction: "next" | "prev") => {
      if (swiper) {
        if (direction === "next") {
          swiper.slideNext();
        } else {
          swiper.slidePrev();
        }
      }
    },
    [swiper]
  );

  useEffect(() => {
    if (!prefersReducedMotion() && window.innerWidth >= 768) {
      setAutoPlay(true);
    }
  }, []);

  return (
    <Wrapper className={styles.root}>
      <Row justify="center">
        <Col sm={1} md={1} className={styles.buttonCol}>
          {slides.length > 1 && (
            <CarouselButton
              direction={isRtl ? "right" : "left"}
              size="large"
              onClick={() => handleClick("prev")}
              stroke="white"
            />
          )}
        </Col>
        <Col lg={8} sm={10}>
          {/* Show the first campaign card until Swiper has initialized to avoid SSR issues */}
          <div style={{ display: swiper ? "none" : "block" }}>{slides[0]}</div>
          <SwiperCarousel
            style={{ display: swiper ? "block" : "none" }}
            // Use slides.length as a key to force a re-render.
            // Fixes various bugs where Swiper does not update correctly.
            key={slides.length}
            carouselItems={slides}
            itemTypeMessage="teach campaigns"
            centeredSlides
            slidesPerView={1}
            onSwiper={swiper => setSwiper(swiper)}
            speed={500}
            spaceBetween={20}
            pagination={
              slides.length > 1
                ? {
                    clickable: true,
                    horizontalClass: styles.pagination,
                    bulletClass: styles.bullet,
                    bulletActiveClass: styles.bulletActive
                  }
                : false
            }
            autoplay={
              autoplay
                ? {
                    delay: 7_000,
                    disableOnInteraction: true,
                    pauseOnMouseEnter: true
                  }
                : false
            }
            loop={true}
            className={styles.carouselContainer}
          />
        </Col>
        <Col md={1} sm={1} className={styles.buttonCol}>
          {slides.length > 1 && (
            <CarouselButton
              direction={isRtl ? "left" : "right"}
              size="large"
              onClick={() => handleClick("next")}
              stroke="white"
            />
          )}
        </Col>
      </Row>
    </Wrapper>
  );
};
export default TeachCampaignCarousel;
