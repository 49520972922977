import React from "react";
import BlockContent from "../../components/BlockContent/BlockContent";
import CallToActionButton from "../../components/CallToActionButton/CallToActionButton";
import Card from "../../components/Card/Card";
import Image, { ImageRatios } from "../../components/Image/Image";
import { internalLinkUrl } from "../../components/InternalLink/InternalLink";
import Link from "../../components/Link/Link";
import { useLanguage } from "../../i18n/LanguageContext";
import { SanityRawContent } from "../../model/common";
import { SanityTeachCampaign } from "../../model/teach";
import styles from "./TeachCampaignItem.module.scss";

const TeachCampaignItem = ({
  _rawContent,
  image,
  callToAction
}: SanityTeachCampaign) => {
  const externalLink = callToAction.externalLink?.href;
  const internalLink = callToAction.internalLink?.reference;
  const query = callToAction.internalLink?.query;
  const fragment = callToAction.internalLink?.fragment;
  const language = useLanguage();
  const to = internalLink
    ? internalLinkUrl(internalLink, query, fragment, language)
    : externalLink || "";
  const content = _rawContent.map((c: SanityRawContent) => ({
    ...c,
    style: c.style === "h1" ? "h2" : c.style
  }));
  return (
    <Card className={styles.root} dir="ltr">
      <BlockContent
        className={styles.content}
        bodyVariant="footerHeading"
        content={content}
        after={[
          <CallToActionButton
            key="cta"
            node={{
              primary: true,
              title: callToAction.title,
              externalLink,
              internalLink,
              query,
              fragment
            }}
          />
        ]}
      />
      <div className={styles.imageWrapper}>
        <Link to={to} className={styles.imageLink}>
          <Image
            aspectRatio={ImageRatios.Landscape_3_2}
            widths={470}
            image={image}
            loading="eager"
          />
        </Link>
      </div>
    </Card>
  );
};

export default TeachCampaignItem;
